import { product_export_download_url } from "@/constants/urls";
import { INotification } from "@/interfaces/INotification";
import { DownloadFile_, getRelativeTime } from "@/lib/utils";
import React from "react";
import { toast } from "./ui/use-toast";

interface NotificationProps {
  notification: INotification;
}

const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const handleDownloadExportFile = async () => {
    toast({
      description: "Préparation du téléchargement du fichier...",
      variant: "success",
    });
    const response = await DownloadFile_(
      product_export_download_url + notification.idEntity,
      "Export for " + notification.idEntity + ".xlsx",
      {}
    );
    if (response.status === 200) {
      toast({
        title: "Succès",
        description: "Le fichier a été téléchargé avec succès",
        variant: "success",
      });
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors du téléchargement du fichier",
        variant: "destructive",
      });
    }
    // Implement your download logic here
  };

  return (
    <div className="flex-1 space-y-1">
      <div className="flex items-center justify-between">
        <h4 className="text-sm font-medium">
          {notification.title + " N°" + notification.idEntity}
        </h4>
        <p className="text-xs text-muted-foreground">
          {getRelativeTime(notification.notificationDate)}
        </p>
      </div>
      <p className="text-sm text-muted-foreground">{notification.message} </p>
      <div className="flex justify-start">
        <span
          className="text-blue-500 cursor-pointer hover:underline"
          onClick={handleDownloadExportFile}
        >
          Télécharger
        </span>
      </div>
    </div>
  );
};

export default Notification;
