import React, { useState } from "react";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { checkICE, checkPattente, checkRib, checkEmail } from "@/lib/utils";
import { Upload } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { PaymentTypes } from "@/constants/constants";

interface FormField {
  label: string;
  id: string;
  placeholder: string;
  value: string | number;
  onChange: (value: string | number) => void;
  validation?: (value: string) => string | null;
  supplierType?: number;
  hasUploadButton?: boolean;
  onFileChange?: (file: File | null) => void;
  fileName?: string;
  selectInput?: boolean;
  inputType?: string;
}

interface FormData {
  address: string;
  bankName: string;
  billingAddress: string;
  email: string;
  firstName: string;
  ice: string;
  id: number;
  identifiantSupplier: number;
  label: string;
  lastName: string;
  pattente: string;
  rc: string;
  rib: string;
  socialreason: string;
  supplierCode: string;
  rcFile: File | null;
  iceFile: File | null;
  ribFile: File | null;
  dureeEcheancesEnJours: number;
  TypePaiment: number;
}

interface SupplierCreateFormProps {
  formData: FormData;
  updateField: (field: keyof FormData) => (value: string | number) => void;
  updateFile: (
    field: "rcFile" | "iceFile" | "ribFile"
  ) => (file: File | null) => void;
  supplierType: number;
}

const FormInput: React.FC<FormField> = ({
  label,
  id,
  placeholder,
  value,
  onChange,
  validation,
  supplierType,
  hasUploadButton,
  onFileChange,
  fileName,
  selectInput,
  inputType,
}) => {
  const [error, setError] = useState<string | null>(null);

  const handleChange = (newValue: string | number) => {
    if (inputType === "number") {
      onChange(Number(newValue));
    } else {
      onChange(newValue);
    }

    if (validation && typeof newValue === "string") {
      const validationResult = validation(newValue);
      setError(validationResult);
    }
  };

  return (
    <div className="space-y-1">
      <Label htmlFor={id}>{label}</Label>
      <div className="flex items-center space-x-2">
        {!selectInput && (
          <Input
            id={id}
            placeholder={placeholder}
            type={inputType || "text"}
            min={0}
            value={value}
            disabled={
              (supplierType === 1 || supplierType === 2) && id !== "due-date"
            }
            onChange={(e) => handleChange(e.target.value)}
            className={`flex-grow ${error ? "border-red-500" : ""}`}
          />
        )}
        {selectInput && (
          <Select
            onValueChange={(value) => {
              handleChange(Number(value));
            }}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {PaymentTypes.map((type) => (
                  <SelectItem key={type.id} value={type.id.toString()}>
                    {type.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        )}
        {hasUploadButton && supplierType === 0 && (
          <div className="flex items-center space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => {
                const input = document.createElement("input");
                input.type = "file";
                input.onchange = (e) => {
                  const file =
                    (e.target as HTMLInputElement).files?.[0] || null;
                  if (onFileChange) onFileChange(file);
                };
                input.click();
              }}
            >
              <Upload className="h-4 w-4" />
            </Button>
          </div>
        )}
      </div>
      {fileName && <span className="text-xs text-gray-500">{fileName}</span>}
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};

const SupplierCreateForm: React.FC<SupplierCreateFormProps> = ({
  formData,
  updateField,
  updateFile,
  supplierType,
}) => {
  const formFields: FormField[] = [
    {
      label: "Identifiant fournisseur *",
      id: "supplierId",
      placeholder: "Entrez l'identifiant",
      value: formData.supplierCode,
      onChange: updateField("supplierCode"),
    },
    {
      label: "Libellé fournisseur *",
      id: "label",
      placeholder: "Entrez le libellé",
      value: formData.label,
      onChange: updateField("label"),
    },
    {
      label: "RC (Registre du commerce)",
      id: "rc",
      placeholder: "Entrez le RC",
      value: formData.rc,
      onChange: updateField("rc"),
      hasUploadButton: true,
      onFileChange: updateFile("rcFile"),
      fileName: formData.rcFile?.name,
    },
    {
      label: "Raison Sociale *",
      id: "social-reason",
      placeholder: "Entrez la raison sociale",
      value: formData.socialreason,
      onChange: updateField("socialreason"),
    },
    {
      label: "Prénom *",
      id: "first-name",
      placeholder: "Entrez le prénom",
      value: formData.firstName,
      onChange: updateField("firstName"),
    },
    {
      label: "Nom *",
      id: "last-name",
      placeholder: "Entrez le nom",
      value: formData.lastName,
      onChange: updateField("lastName"),
    },
    {
      label: "RIB *",
      id: "rib",
      placeholder: "Entrez le RIB",
      value: formData.rib,
      onChange: updateField("rib"),
      validation: (value) =>
        checkRib(value) ? null : "Le RIB doit comporter 24 caractères",
      hasUploadButton: true,
      onFileChange: updateFile("ribFile"),
      fileName: formData.ribFile?.name,
    },
    {
      label: "Banque *",
      id: "bank-name",
      placeholder: "Entrez le nom de la banque",
      value: formData.bankName,
      onChange: updateField("bankName"),
    },
    {
      label: "ICE (Identifiant fiscal) *",
      id: "ice",
      placeholder: "Entrez l'ICE",
      value: formData.ice,
      onChange: updateField("ice"),
      validation: (value) =>
        checkICE(value) ? null : "L'ICE doit comporter 15 caractères",
      hasUploadButton: true,
      onFileChange: updateFile("iceFile"),
      fileName: formData.iceFile?.name,
    },
    {
      label: "Pattente",
      id: "pattente",
      placeholder: "Entrez la pattente",
      value: formData.pattente,
      onChange: updateField("pattente"),
      validation: (value) =>
        checkPattente(value)
          ? null
          : "La pattente doit comporter 10 caractères",
    },
    {
      label: "Adresse",
      id: "address",
      placeholder: "Entrez l'adresse",
      value: formData.address,
      onChange: updateField("address"),
    },
    {
      label: "Adresse de facturation",
      id: "billing-address",
      placeholder: "Entrez l'adresse de facturation",
      value: formData.billingAddress,
      onChange: updateField("billingAddress"),
    },
    {
      label: "Email",
      id: "email",
      placeholder: "Entrez l'email",
      value: formData.email,
      onChange: updateField("email"),
      validation: (value) =>
        checkEmail(value) ? null : "L'email n'est pas valide",
    },
    {
      label: "Durée d'échéances (jours)",
      id: "due-date",
      placeholder: "Entrez la durée d'échéances",
      inputType: "number",
      value: formData.dureeEcheancesEnJours,
      onChange: updateField("dureeEcheancesEnJours"),
    },
    {
      label: "Type de paiement",
      id: "payment-type",
      placeholder: "Type de paiement",
      inputType: "number",
      value: formData.TypePaiment,
      selectInput: true,
      onChange: updateField("TypePaiment"),
    },
  ];

  return (
    <div className="grid gap-4 py-4 mr-4 ml-2">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        {formFields.map((field) => (
          <FormInput key={field.id} {...field} supplierType={supplierType} />
        ))}
      </div>
    </div>
  );
};

export default SupplierCreateForm;
