import React, { useEffect } from "react";

import { Label } from "./ui/label";
import { Switch } from "./ui/switch";
import { Button } from "./ui/button";
import { ISupplier, ISupplierDocument } from "@/interfaces/ISupplier";
import { updateSupplier } from "@/services/SupplierUpdateService";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@/store/store";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "./ui/use-toast";
import { IErrorResponse } from "@/interfaces/IErrorResponse";
import SupplierInput from "./SupplierInput";
import { Input } from "./ui/input";
import LoaderButton from "./LoaderButton";
import { TabsContent } from "./ui/tabs";
import { Check } from "lucide-react";
import SupplierStatusTag from "./SupplierStatusTag";

import { validateSupplier } from "@/services/SupplierValidationService";
import { uploadSupplierDocs } from "@/services/SupplierDocumentUpload";
import {
  checkModificationDesFournisseurs,
  checkValidationDesFournisseurs,
} from "@/lib/roleHelper";

interface SupplierInformationSectionProps {
  supplier: ISupplier;
}

const SupplierInformationSection: React.FC<SupplierInformationSectionProps> = ({
  supplier,
}) => {
  const validSupplier = supplier.valid;
  const dispatch = useDispatch();
  const [checked, setChecked] = React.useState<boolean>(false);
  const [providerCode, setProviderCode] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [ribFile, setRibFile] = React.useState<File>();
  const [rcFile, setRcFile] = React.useState<File>();
  const [iceFile, setIceFile] = React.useState<File>();
  const [rfFile, setRfFile] = React.useState<File>();

  const [supplierInfo, setSupplierInfo] = React.useState<ISupplier>(
    {} as ISupplier
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const handleValidateSupplier = async () => {
    const response = await validateSupplier(supplier.id);
    if (response.status === 200) {
      dispatch(setRefreshing(!isRefreshing));
      toast({
        title: "Fournisseur validé.",
        description: "Le fournisseur a été validé avec succès",
        variant: "success",
      });
    } else {
      toast({
        title: "Erreur",
        description: response.data.message,
        variant: "destructive",
      });
    }
  };

  useEffect(() => {
    console.log("RIB FILE", ribFile);
  }, [ribFile]);

  useEffect(() => {
    setSupplierInfo(supplier);
    if (supplier.providerCode) {
      setProviderCode(supplier.providerCode.toString());
    }
  }, [supplier]);

  const handleSubmit = async () => {
    console.log(supplierInfo);
    setIsLoading(true);
    const response = await updateSupplier(supplierInfo);

    if (response.status === 200) {
      const response_ = await uploadSupplierDocs(
        [
          { type: 1, file: rcFile },
          { type: 2, file: iceFile },
          { type: 3, file: ribFile },
          { type: 4, file: rfFile },
        ] as ISupplierDocument[],
        supplierInfo.id
      );
      if (response_.status === 200) {
        toast({
          title: "Fournisseur modifié.",
          description: "Le fournisseur a été modifié avec succès",
          variant: "success",
        });
        dispatch(setRefreshing(!isRefreshing));
      } else {
        toast({
          title: "Erreur",
          description: response_.data.message,
          variant: "destructive",
        });
        setIsLoading(false);
      }
      setChecked(false);
    } else {
      const error = response as unknown as IErrorResponse;
      const message = error.message || error.exception;
      toast({
        title: "Erreur",
        description: message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };
  useEffect(() => {
    setSupplierInfo(supplier);
    if (supplier.providerCode) {
      setProviderCode(supplier.providerCode.toString());
    }
  }, [supplier]);

  useEffect(() => {
    console.log("supplier info ", supplierInfo);
  }, [supplierInfo]);

  return (
    <TabsContent value="single">
      <div className="flex justify-between items-center mb-8 mt-8">
        <div className="h-4 gap-4 flex-row flex align-middle">
          <h2 className="text-xl font-bold">{supplierInfo.label}</h2>
          <SupplierStatusTag status={validSupplier} />
        </div>

        <div className="flex items-center space-x-2 gap-2">
          {validSupplier === false && checkValidationDesFournisseurs() && (
            <div className="flex items-center space-x-2">
              <Button
                className="gap-2  "
                variant="ghost"
                onClick={() => {
                  handleValidateSupplier();
                }}
              >
                <Check className="w-6 h-6 " color="green" />
                <span className="text-sm font-medium">Valider</span>
              </Button>
            </div>
          )}
          {checkModificationDesFournisseurs() && !supplier.valid && (
            <div className="flex items-center space-x-2">
              <Switch
                aria-label="Edit supplier information"
                className="relative inline-flex h-6 w-11 items-center rounded-full"
                id="editSupplier"
                checked={checked}
                onCheckedChange={setChecked}
              >
                <span className="sr-only">
                  Modifier les informations du fournisseur
                </span>
                <span
                  aria-hidden="true"
                  className="inline-block h-4 w-4 transform rounded-full bg-white transition"
                />
              </Switch>
              <Label className="text-sm font-medium" htmlFor="editSupplier">
                Modifier
              </Label>
            </div>
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <Label className="mb-2" htmlFor="label">
            Identifiant fournisseur
          </Label>
          <Input
            defaultValue={providerCode}
            disabled={!checked}
            type="number"
            id="postalCode"
            value={supplierInfo.providerCode}
            onChange={(e) => {
              setSupplierInfo({
                ...supplierInfo,
                providerCode: parseInt(e.target.value),
              });
            }}
          />
        </div>
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="label"
          label="Libellé fournisseur"
          defaultValue={supplierInfo.label}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="rc"
          label="RC"
          defaultValue={supplierInfo.rc}
          hasUploadButton
          file={rcFile}
          setFile={setRcFile}
          fileLink={supplierInfo.pjRc}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="socialreason"
          label="Raison Sociale"
          defaultValue={supplierInfo.socialreason}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="firstName"
          label="Prénom"
          defaultValue={supplierInfo.firstName}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="lastName"
          label="Nom"
          defaultValue={supplierInfo.lastName}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="rib"
          label="RIB"
          defaultValue={supplierInfo.rib}
          hasUploadButton
          file={ribFile}
          setFile={setRibFile}
          fileLink={supplierInfo.pjRib}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="bankName"
          label="Banque"
          defaultValue={supplierInfo.bankName}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="ice"
          label="ICE"
          defaultValue={supplierInfo.ice}
          hasUploadButton
          file={iceFile}
          setFile={setIceFile}
          fileLink={supplierInfo.pjIce}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="pattente"
          label="Patente"
          defaultValue={supplierInfo.pattente}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="address"
          label="Adresse"
          defaultValue={supplierInfo.address}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="billingAddress"
          label="Adresse de Facturation"
          defaultValue={supplierInfo.billingAddress}
        />
        <SupplierInput
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="email"
          label="Email"
          defaultValue={supplierInfo.email}
        />
        {supplierInfo.pjRegulariteFiscale && (
          <SupplierInput
            noInput
            supplierInfo={supplierInfo}
            setSupplierInfo={setSupplierInfo}
            isDisabled={checked}
            fieldName="raisonFiscale"
            label="Réguarité fiscale :"
            defaultValue={"Régularité fiscale"}
            hasUploadButton
            file={rfFile}
            setFile={setRfFile}
            fileLink={supplierInfo.pjRegulariteFiscale}
          />
        )}
        <SupplierInput
          inputType="number"
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="dureeEcheancesEnJours"
          label="Duree Echeances (Jours)"
          defaultValue={supplierInfo.dureeEcheancesEnJours?.toString() || ""}
        />
        <SupplierInput
          inputType="select"
          supplierInfo={supplierInfo}
          setSupplierInfo={setSupplierInfo}
          isDisabled={checked}
          fieldName="typePaiment"
          label="Type de paiement"
          defaultValue={supplierInfo.typePaiment?.toString() || ""}
        />
      </div>
      {checked && (
        <div className="flex justify-end space-x-2 mt-4">
          <Button
            variant="outline"
            onClick={() => {
              setChecked(!checked);
            }}
          >
            Annuler
          </Button>
          <LoaderButton
            isLoading={isLoading}
            name="Enregistrer"
            handleSubmit={handleSubmit}
          />
        </div>
      )}
    </TabsContent>
  );
};

export default SupplierInformationSection;
