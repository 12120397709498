import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "./ui/dialog";
import { Button } from "./ui/button";
import { ScrollArea } from "./ui/scroll-area";
import {
  defaultFormData,
  SUPPLIER_CREATE_DIALOG,
  supplierTypes,
} from "@/constants/constants";
import { RootState } from "@/store/store";
import { closeDialog } from "@/store/reducers/dialogReducer";
import { createSupplier } from "@/services/SupplierCreateService";
import { ISupplier, ISupplierDocument } from "@/interfaces/ISupplier";
import { setRefreshing } from "@/store/reducers/refreshReducer";
import { toast } from "./ui/use-toast";
import LoaderButton from "./LoaderButton";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import SupplierCreateForm from "./SupplierCreateForm";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { checkSupplier } from "@/services/SupplierCheckService";
import { IServerResponse_ } from "@/interfaces/IRequestResponse";
import { LoaderCircle, Upload } from "lucide-react";
import { uploadSupplierDocs } from "@/services/SupplierDocumentUpload";
import { checkICE, checkRib } from "@/lib/utils";

interface FormData {
  address: string;
  bankName: string;
  billingAddress: string;
  email: string;
  firstName: string;
  ice: string;
  id: number;
  identifiantSupplier: number;
  label: string;
  lastName: string;
  pattente: string;
  rc: string;
  rib: string;
  socialreason: string;
  supplierCode: string;
  rcFile: File | null;
  iceFile: File | null;
  ribFile: File | null;
  dureeEcheancesEnJours: number;
  TypePaiment: number;
}

const SupplierCreateDialog: React.FC = () => {
  const dispatch = useDispatch();
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [checkMessage, setCheckMessage] = React.useState<string>("");
  const [checkResponse, setCheckResponse] = React.useState<
    IServerResponse_<ISupplier>
  >({} as IServerResponse_<ISupplier>);
  const [selectedSupplierType, setSelectedSupplierType] =
    React.useState<number>(0);
  const [rfFile, setRfFile] = React.useState<File>({} as File);
  const [supplierValue, setSupplierValue] = React.useState<string>("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isSupplierCheckLoading, setIsSupplierCheckLoading] =
    React.useState(false);
  const { isOpen, dialogType } = useSelector(
    (state: RootState) => state.dialogReducer
  );
  const isOpened = isOpen && dialogType === SUPPLIER_CREATE_DIALOG;

  const [formData, setFormData] = React.useState<FormData>(defaultFormData);

  const checkForSupplier = async () => {
    setIsSupplierCheckLoading(true);
    const response = await checkSupplier(
      selectedSupplierType,
      supplierValue.trim()
    );
    if (response.status === 200) {
      setCheckResponse(response.data);
      console.log("Supplier checked", response.data);
      setFormData({ ...formData, ...response.data.data });
      setCheckMessage("");
      setIsSupplierCheckLoading(false);
    } else {
      console.log("Error checking supplier", response.data.message);
      setCheckMessage(response.data.message);

      checkResponse.data && setCheckResponse({} as IServerResponse_<ISupplier>);
      setIsSupplierCheckLoading(false);
    }
    setIsSupplierCheckLoading(false);
  };

  const updateField = (field: keyof FormData) => (value: string | number) => {
    setFormData((prev) => ({
      ...prev,
      [field]:
        field === "dureeEcheancesEnJours" || field === "TypePaiment"
          ? Number(value)
          : value,
    }));
  };

  const updateFile =
    (field: "rcFile" | "iceFile" | "ribFile") => (file: File | null) => {
      setFormData((prev) => ({ ...prev, [field]: file }));
      console.log("File updated", formData.rcFile);
    };

  const handleClose = () => {
    dispatch(closeDialog());
    setSelectedSupplierType(0);
    setCheckResponse({} as IServerResponse_<ISupplier>);
    setCheckMessage("");
    setFormData(defaultFormData);
    setSupplierValue("");
    setRfFile({} as File);
  };

  const handleRfFileChange = (file: File) => {
    setRfFile(file);
    console.log("Rf file", file);
  };

  const handleSupplierTypeChange = (value: string) => {
    setSelectedSupplierType(parseInt(value));
    setCheckResponse({} as IServerResponse_<ISupplier>);
    supplierValue && setSupplierValue("");
    setCheckMessage("");
    setFormData(defaultFormData);
  };

  const handleSubmit = async () => {
    const supplier: ISupplier = {
      id: selectedSupplierType === 2 ? formData.id : 0,
      label: formData.label,
      socialreason: formData.socialreason,
      firstName: formData.firstName,
      lastName: formData.lastName,
      rib: formData.rib,
      bankName: formData.bankName,
      ice: formData.ice,
      pattente: formData.pattente,
      rc: formData.rc,
      email: formData.email,
      address: formData.address,
      billingAddress: formData.billingAddress,
      providerCode: parseInt(formData.supplierCode),
      productCount: 0,
      dureeEcheancesEnJours: formData.dureeEcheancesEnJours,
      typePaiment: formData.TypePaiment,
    };

    setIsLoading(true);

    const response = await createSupplier(supplier, selectedSupplierType);
    if (response.status === 200) {
      console.log("Supplier created", formData);
      const response_ = await uploadSupplierDocs(
        [
          { type: 1, file: formData.rcFile },
          { type: 2, file: formData.iceFile },
          { type: 3, file: formData.ribFile },
          { type: 4, file: rfFile },
        ] as ISupplierDocument[],
        parseInt(response.data.data)
      );
      if (response_.status === 200) {
        dispatch(setRefreshing(!isRefreshing));
        toast({
          title: "Fournisseur créé",
          description: "Le fournisseur a été créé avec succès",
          variant: "success",
        });
      } else {
        toast({
          title: "Erreur",
          description: response_.data.message,
          variant: "destructive",
        });
        setIsLoading(false);
      }
      setIsLoading(false);
      handleClose();
    } else {
      toast({
        title: "Erreur",
        description: response.data.message,
        variant: "destructive",
      });
      setIsLoading(false);
    }
  };

  const isFormValid = () => {
    // Add your form validation logic here
    return (
      formData.supplierCode &&
      formData.label &&
      formData.socialreason &&
      formData.firstName &&
      formData.lastName &&
      formData.rib &&
      formData.bankName &&
      formData.ice &&
      formData.dureeEcheancesEnJours &&
      formData.dureeEcheancesEnJours > 0 &&
      formData.TypePaiment &&
      checkICE(formData.ice) &&
      checkRib(formData.rib)
    );
  };

  useEffect(() => {
    if (supplierValue !== "") {
      const getData = setTimeout(() => {
        checkForSupplier();
      }, 1000);
      return () => clearTimeout(getData);
    } else {
      setCheckMessage("");
      setCheckResponse({} as IServerResponse_<ISupplier>);
    }
    setIsSupplierCheckLoading(false);
  }, [supplierValue]);

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  return (
    <Dialog open={isOpened} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[100vh]" hasClose={true}>
        <div className="flex items-center justify-between flex-row px-2">
          <DialogTitle>Créer un fournisseur</DialogTitle>
          <Select
            onValueChange={(value) => handleSupplierTypeChange(value)}
            defaultValue="0"
          >
            <SelectTrigger className="w-[30vh]">
              <SelectValue placeholder="Selectionner un type" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {supplierTypes.map((type) => (
                  <SelectItem key={type.id} value={type.type.toString()}>
                    {type.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
        </div>

        {selectedSupplierType === 0 ? (
          <ScrollArea className="h-[60vh] overflow-auto mt-2">
            <SupplierCreateForm
              formData={formData}
              updateField={updateField}
              updateFile={updateFile}
              supplierType={selectedSupplierType}
            />
          </ScrollArea>
        ) : (
          <>
            <div className="space-y-1 px-2">
              <Label>
                {selectedSupplierType === 1 ? "Vendeur" : "Fournisseur"}
              </Label>
              <div className="flex items-center w-full gap-4">
                <Input
                  placeholder={
                    selectedSupplierType === 1
                      ? "Identifiant vendeur"
                      : "Identifiant fournisseur"
                  }
                  value={supplierValue}
                  onChange={(e) => setSupplierValue(e.target.value)}
                />
                {isSupplierCheckLoading && (
                  <LoaderCircle
                    className="animate-spin"
                    color="grey"
                    size={20}
                  />
                )}
              </div>
              {checkMessage !== "" && (
                <span className="text-xs ml-2 text-red-500">
                  {checkMessage}
                </span>
              )}
            </div>
            {checkResponse.data && (
              <ScrollArea className="h-[60vh] overflow-auto mt-2">
                <SupplierCreateForm
                  formData={formData}
                  updateField={updateField}
                  updateFile={updateFile}
                  supplierType={selectedSupplierType}
                />
              </ScrollArea>
            )}
          </>
        )}

        <div className="flex  flex-1 justify-between mt-4">
          {selectedSupplierType === 0 && (
            <div className="flex flex-1 items-center space-x-2">
              <Button
                variant="outline"
                className="gap-2"
                onClick={() => {
                  const input = document.createElement("input");
                  input.type = "file";
                  input.multiple = true;
                  input.accept = [".png", ".jpg", ".jpeg", ".pdf"].join(",");
                  input.onchange = (e) => {
                    const target = e.target as HTMLInputElement;
                    if (target.files && target.files.length > 0) {
                      handleRfFileChange(target.files[0]);
                    }
                  };
                  input.click();
                }}
              >
                <Upload className="w-4 h-4" />
                <span className="text-sm">Régularité fiscale</span>
              </Button>
              {rfFile && (
                <span className="text-xs text-gray-500">{rfFile.name}</span>
              )}
            </div>
          )}
          <div className="flex flex-1 items-center space-x-2 justify-end">
            <Button variant="outline" onClick={handleClose}>
              Annuler
            </Button>
            <LoaderButton
              isLoading={isLoading}
              handleSubmit={handleSubmit}
              name="Créer"
              disabled={!isFormValid()}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SupplierCreateDialog;
