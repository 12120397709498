import { Bell, ChevronDownIcon, MenuIcon } from "lucide-react";
import { Button } from "./ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { v4 as uuidv4 } from "uuid";
import { useMsal } from "@azure/msal-react";
import Notification from "./Notification";
import { INotification } from "@/interfaces/INotification";
import { useEffect, useState } from "react";
import { getNotifications } from "@/services/NotificationService";
import ToggleSideBar from "./ToggleSideBar";
interface HeaderProps {
  setOpen: (open: boolean) => void;
  open: boolean;
}

export default function Header({ setOpen, open }: HeaderProps) {
  const { accounts, instance } = useMsal();
  const [notifications, setNotifications] = useState<INotification[]>(
    [] as INotification[]
  );
  const [, setHasNewNotifications] = useState<boolean>(false);
  const location = window.location.pathname;
  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    instance.logout();
  };

  useEffect(() => {
    const fetchNotifications = async () => {
      const response = await getNotifications();
      if (response.status === 200) {
        const newNotifications = response.data;
        const hasNew = newNotifications.some(
          (newNotification) =>
            !notifications.some(
              (notification) => notification.id === newNotification.id
            )
        );
        setHasNewNotifications(hasNew);
        setNotifications(newNotifications);
      } else {
        console.log("Error fetching notifications");
      }
    };

    fetchNotifications();
  }, [location]);

  return (
    <header className="flex min-h-[65px] items-center justify-between px-6 lg:px-12 bg-[#0b4d54]  drop-shadow-xl  border-b w-full ">
      <div className="lg:hidden">
        <button
          className="rounded-lg bg-teal-700 p-2 text-white transition-colors hover:bg-teal-800 hover:text-white "
          onClick={() => {
            setOpen(!open);
          }}
        >
          <span className="sr-only">Toggle sidebar</span>
          <MenuIcon className="h-5 w-5" />
        </button>
      </div>
      <div className="flex items-center gap-4">
        <a className="flex items-center gap-2 font-semibold" href="#">
          <span className="text-lg text-white">Retail</span>
        </a>
      </div>
      <div className="flex items-center gap-4">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center gap-3">
              <Button
                size="icon"
                className="bg-[#0b4d54]  hover:bg-teal-700"
                onClick={() => setHasNewNotifications(false)}
              >
                <Bell className={`h-5 w-5`} />
                <span className="sr-only">Toggle notification menu</span>
              </Button>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="space-y-4 px-2">
            <div className="flex items-center justify-between mb-4 p-2 gap-4">
              <h3 className="text-lg font-medium">Notifications</h3>
            </div>
            {notifications.slice(-3).map((notification) => (
              <DropdownMenuItem key={uuidv4()}>
                <Notification key={uuidv4()} notification={notification} />
              </DropdownMenuItem>
            ))}
            <div className="flex items-center mb-4 p-2">
              <Button variant="outline" size="sm" className="w-full">
                Voir toutes les notifications
              </Button>
            </div>
          </DropdownMenuContent>
        </DropdownMenu>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div className="flex items-center gap-3">
              <div className="grid gap-0.5 text-sm">
                <div className=" text-white ">{accounts[0].username}</div>
              </div>
              <Button size="icon" className="bg-[#0b4d54]  hover:bg-teal-700">
                <ChevronDownIcon className="h-5 w-5" color="white" />
                <span className="sr-only">Toggle user menu</span>
              </Button>
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleLogout}>
              Se déconnecter
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <ToggleSideBar open={open} setOpen={setOpen} />
    </header>
  );
}
