import ProductBulkAssignTab from "@/components/ProductBulkAssignTab";
import ProductSingleAssignTab from "@/components/ProductSingleAssignTab";
import ProofsTab from "@/components/ProofsTab";
import TableSkeleton from "@/components/TableSkeleton";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import { ISupplier, ISupplierProduct } from "@/interfaces/ISupplier";
import { getSupplierDetails } from "@/services/SupplierDetailsService";
import { getSupplierProducts } from "@/services/SupplierProdutListService";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const ProductAssignPage: React.FC = () => {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState<ISupplierProduct[]>(
    [] as ISupplierProduct[]
  );
  const [supplierDetails, setSupplierDetails] = useState<ISupplier>(
    {} as ISupplier
  );

  const fetchProducts = async () => {
    const response = await getSupplierProducts(parseInt(id || ""));

    if (response.status === 200) {
      setProducts(response.data);
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des produits du fournisseur",
        variant: "destructive",
      });
    }
  };

  const fetchSupplierDetails = async () => {
    const response = await getSupplierDetails(parseInt(id || ""));
    if (response.status === 200) {
      setSupplierDetails(response.data);
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors de la récupération des détails du fournisseur",
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProducts();
    fetchSupplierDetails();
  }, []);

  return (
    <div className="flex-1 px-12 py-3">
      {isLoading ? (
        <TableSkeleton cols={4} rows={5} />
      ) : (
        <>
          <h1 className="text-2xl font-bold mb-4">Assigner des produits</h1>
          <Tabs className="w-full" defaultValue="single">
            <TabsList className="w-full grid grid-cols-3 gap-4">
              <TabsTrigger className="col-span-1" value="single">
                Affection Simple
              </TabsTrigger>
              <TabsTrigger className="col-span-1" value="bulk">
                Affectation en masse
              </TabsTrigger>
              <TabsTrigger className="col-span-1" value="proof">
                Preuves
              </TabsTrigger>
            </TabsList>
            <ProductSingleAssignTab
              products={products}
              supplier={supplierDetails}
            />
            <ProductBulkAssignTab supplier={supplierDetails} />
            <ProofsTab supplierId={parseInt(id || "")} />
          </Tabs>
          <Toaster />
        </>
      )}
    </div>
  );
};

export default ProductAssignPage;
