import TableSkeleton from "@/components/TableSkeleton";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import UserCreateDialog from "@/components/UserCreateDialog";
import UserDeleteDialog from "@/components/UserDeleteDialog";
import UserEditDialog from "@/components/UserEditDialog";
import UserTable from "@/components/UserTable";
import UtilityBar from "@/components/UtilityBar";
import { IUser } from "@/interfaces/IUser";
import { getUsers } from "@/services/UserListService";
import { RootState } from "@/store/store";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const UserPage: React.FC = () => {
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([] as IUser[]);
  const [users_, setUsers] = useState<IUser[]>([] as IUser[]);
  const [isLoading, setIsLoading] = useState(false);
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const fetchUsers = async () => {
    setIsLoading(true);
    const response = await getUsers("");
    if (response.status === 200) {
      setUsers(response.data.data as IUser[]);
    } else {
      setUsers([]);
      toast({
        title: "Erreur",
        description:
          " Une erreur s'est produite lors de la récupération des utilisateurs",
        variant: "destructive",
      });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [isRefreshing]);

  useEffect(() => {
    setFilteredUsers(
      users_.filter((user) =>
        user.email.toLowerCase().includes(searchKeyword.toLowerCase())
      )
    );
  }, [searchKeyword, users_]);
  return (
    <div className="flex-1 px-12 py-3">
      <h1 className="text-2xl font-bold mb-4">Users</h1>
      <UtilityBar hasCreateButton={true} />
      {isLoading ? (
        <TableSkeleton rows={10} cols={6} />
      ) : (
        <>
          <ScrollArea className="h-[60vh]">
            <UserTable users={filteredUsers} />
          </ScrollArea>
        </>
      )}
      <UserCreateDialog />
      <UserDeleteDialog />
      <UserEditDialog />
      <Toaster />
    </div>
  );
};

export default UserPage;
