import ProductPriceHistoryDialog from "@/components/ProductPriceHistoryDialog";
import SupplierInformationSection from "@/components/SupplierInformationSection";
import SupplierProductSection from "@/components/SupplierProductsSection";
import TableSkeleton from "@/components/TableSkeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Toaster } from "@/components/ui/toaster";
import { toast } from "@/components/ui/use-toast";
import { ISupplier, ISupplierProduct } from "@/interfaces/ISupplier";
import { getSupplierDetails } from "@/services/SupplierDetailsService";
import { getSupplierProducts } from "@/services/SupplierProdutListService";
import { setSupplier } from "@/store/reducers/supplierReducer";
import { RootState } from "@/store/store";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const SupplierDetailsPage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [supplierDetails, setSupplierDetails] = useState<ISupplier>(
    {} as ISupplier
  );
  const [products, setProducts] = useState<ISupplierProduct[]>(
    [] as ISupplierProduct[]
  );
  const [isSupplierLoading, setIsSupplierLoading] = useState(false);
  const [isProductLoading, setIsProductLoading] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState<ISupplierProduct[]>(
    [] as ISupplierProduct[]
  );
  const [validSupplier, setValidSupplier] = useState(false);
  const searchKeyword = useSelector(
    (state: RootState) => state.searchReduer.searchText
  );
  const isRefreshing = useSelector(
    (state: RootState) => state.refreshReducer.isRefreshing
  );

  const fetchProducts = async () => {
    console.log("valid", supplierDetails.valid);
    if (validSupplier) {
      const response = await getSupplierProducts(parseInt(id || ""));
      if (response.status === 200) {
        setProducts(response.data);
        setIsProductLoading(false);
      } else {
        toast({
          title: "Erreur",
          description:
            "Une erreur s'est produite lors de la récupération des produits",
          variant: "destructive",
        });
      }
    }
  };

  const fetchSupplierDetails = async () => {
    const response = await getSupplierDetails(parseInt(id || ""));
    if (response.status === 200) {
      setSupplierDetails(response.data);
      setIsSupplierLoading(false);
    } else {
      toast({
        title: "Erreur",
        description:
          "Une erreur s'est produite lors du chargement du fournisseur",
        variant: "destructive",
      });
    }
  };
  useEffect(() => {
    setIsSupplierLoading(true);
    fetchSupplierDetails();
  }, [isRefreshing]);

  useEffect(() => {
    setIsProductLoading(true);
    fetchProducts();
  }, [isRefreshing, validSupplier]);

  useEffect(() => {
    dispatch(setSupplier(supplierDetails));
  }, [supplierDetails]);

  useEffect(() => {
    setFilteredProducts(
      products?.filter(
        (product) =>
          (product.title &&
            product.title
              .toLowerCase()
              .includes(searchKeyword.toLowerCase())) ||
          (product.gtin &&
            product.gtin.toLowerCase().includes(searchKeyword.toLowerCase())) ||
          (product.productId &&
            product.productId
              .toLowerCase()
              .includes(searchKeyword.toLowerCase()))
      )
    );
  }, [searchKeyword, products]);

  useEffect(() => {
    if (supplierDetails.valid === undefined) {
      setValidSupplier(false);
    } else {
      setValidSupplier(supplierDetails.valid);
    }
  }, [supplierDetails]);

  return (
    <div className="flex-1 px-12 py-3">
      {isSupplierLoading && isProductLoading ? (
        <TableSkeleton rows={5} cols={2} />
      ) : (
        <>
          <Tabs>
            <h1 className="text-2xl font-bold mb-4">Fournisseur</h1>
            <Tabs className="w-full" defaultValue="single">
              <TabsList className="w-full grid grid-cols-2 gap-4">
                <TabsTrigger
                  className={`${validSupplier ? "col-span-1" : "col-span-2"}`}
                  value="single"
                >
                  Informations
                </TabsTrigger>
                {validSupplier === true && (
                  <TabsTrigger className="col-span-1" value="bulk">
                    Produits
                  </TabsTrigger>
                )}
              </TabsList>

              <TabsContent value="single">
                <SupplierInformationSection supplier={supplierDetails} />
              </TabsContent>
              {validSupplier === true && (
                <TabsContent value="bulk">
                  <SupplierProductSection products={filteredProducts} />
                </TabsContent>
              )}
            </Tabs>
          </Tabs>
        </>
      )}
      <ProductPriceHistoryDialog supplier={supplierDetails} />
      <Toaster />
    </div>
  );
};

export default SupplierDetailsPage;
